import * as Sentry from '@sentry/browser'
import { CaptureConsole } from '@sentry/integrations'

function init() {
  if (
    window.navigator.userAgent.indexOf('MSIE') !== -1 || // IE11未満
    window.navigator.userAgent.indexOf('Trident/') !== -1 || // IE11
    /bot|crawl|spider/i.test(window.navigator.userAgent) // bot
  ) {
    return
  }

  const dataset = document.getElementById('sentry_tracking_tag').dataset
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ignores: string[] = JSON.parse(dataset.ignores)
  const isDebug = dataset.debug === 'true'
  const integrations = []

  // デバッグを有効化している場合のみconsole.logの内容も送信する
  if (isDebug) {
    const captureConsoleOptions: { levels: string[] } = {
      levels: ['warn', 'error'],
    }
    integrations.push(
      new CaptureConsole(captureConsoleOptions)
    )
  }

  const browserOptions: Sentry.BrowserOptions = {
    dsn: dataset.dsn,
    sampleRate: parseFloat(dataset.sentry_sample_rate),
    allowUrls: [
      'localhost',
      /https:\/\/([^.]+\.)?tokubai\.co\.jp/, // tokubai.co.jp及びそのサブドメイン
    ],
    // NOTE: ignoreErrors は下記より取得
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    ignoreErrors: ignores
      .map(function (e: string) {
        return new RegExp(e)
      })
      .concat([
        // Random plugins/extensions
        /top\.GLOBALS/,
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        /originalCreateNotification/,
        /canvas\.contentDocument/,
        /MyApp_RemoveAllHighlights/,
        /http:\/\/tt\.epicplay\.com/,
        /Can't find variable: ZiteReader/,
        /jigsaw is not defined/,
        /ComboSearch is not defined/,
        /http:\/\/loading\.retry\.widdit\.com\//,
        /atomicFindClose/,
        // Facebook borked
        /fb_xd_fragment/,
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        /bmi_SafeAddOnload/,
        /EBCallBackMessageReceived/,
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        /conduitPage/,
      ]),
    integrations: integrations,
    release: dataset.release,
    debug: isDebug,
    tracesSampleRate: 0.0, // enableTracing: falseのみでは不十分だったため設定

  }
  Sentry.init(browserOptions)
}

init()
